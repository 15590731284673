import "../sass/styles.scss";
import { globalListeners } from "./helpers/listeners";
const sticky = 105;
const init = () => {
  globalListeners();
};

document.addEventListener("DOMContentLoaded", init);
window.addEventListener("scroll", () => {
    if (window.pageYOffset > sticky) {
        document.body.classList.add("sticky");
      } else {
        document.body.classList.remove("sticky");
      }
});
