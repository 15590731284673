const globalListeners = () => {
  // replace urls in links
  const modalLink = document.querySelectorAll('.modal-link');
  const mobileMenuButton = document.querySelector('.menu-toggle');
  const drawers = document.querySelectorAll('.expand-drawer');
  const app = document.querySelector("html");
  mobileMenuButton.addEventListener("click", (event) => {
    event.stopImmediatePropagation();
    event.preventDefault();
    app.classList.toggle('show-mobile-menu');
  });

  [...modalLink].forEach((mdlnk) => {
    mdlnk.addEventListener("click", (event) => {
      event.preventDefault();
      app.classList.toggle('show-modal');
    });
  });

  [...drawers].forEach((drawer) => {
    drawer.addEventListener("click", (event) => {
      event.preventDefault();
      const drawerObject =  document.querySelector(drawer.dataset.drawer);
      drawerObject.classList.toggle('expand-drawer');
    });
  });

  new Swiper(".scroll-swiper", {
    slidesPerView: 1,
    spaceBetween: 30,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    scrollbar: {
      el: ".swiper-scrollbar",
      hide: false,
      draggable: true,
    },
    breakpoints: {
      800: {
          slidesPerView: 3,
          spaceBetweenSlides: 30
      }
    }
  });
};


export { globalListeners};
